import { ThemeProvider } from '@mui/material/styles'
import { CssBaseline } from '@mui/material'
import { Provider } from 'react-redux'
import store from './stores/index'
import theme from './assets/theme'
import { SettingsProvider } from 'contexts/SettingsContext'
import AppRoute from "./routes";

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <SettingsProvider>
          <div className="App">
            <AppRoute />
          </div>
        </SettingsProvider>
      </ThemeProvider>
    </Provider>
  );
}

export default App;