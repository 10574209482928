import { createSlice } from "@reduxjs/toolkit";

const division = createSlice({
  name: "division",
  initialState: {
    division: {},
  },
  reducers: {
    SET_DIVISION(state, { payload }) {
      return {
        ...state,
        division: payload,
      };
    },
  },
});

export const { SET_DIVISION } = division.actions;
export default division.reducer;
