import PropTypes from "prop-types"
import { forwardRef } from "react"
import MTypographyRoot from "./MTypographyRoot"

const MTypography = forwardRef(
    ({ color, fontWeight, textTransform, verticalAlign, textGradient, opacity, children, ...rest }, ref) => {
        return (
            <MTypographyRoot
                {...rest}
                ref={ref}
                ownerState={{
                    color,
                    textTransform,
                    verticalAlign,
                    fontWeight,
                    opacity,
                    textGradient,
                }}
            >
                {children}
            </MTypographyRoot>
        )
    }
)

MTypography.defaultProps = {
    color: 'dark',
    fontWeight: false,
    textTransform: 'none',
    verticalAlign: 'unset',
    textGradient: false,
    opacity: 1
}

MTypography.propTypes = {
    color: PropTypes.oneOf([
        "inherit",
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "light",
        "dark",
        "text",
        "white",
    ]),
    fontWeight: PropTypes.oneOf([false, "light", "regular", "medium", "bold"]),
    textTransform: PropTypes.oneOf(["none", "capitalize", "uppercase", "lowercase"]),
    verticalAlign: PropTypes.oneOf([
        "unset",
        "baseline",
        "sub",
        "super",
        "text-top",
        "text-bottom",
        "middle",
        "top",
        "bottom",
    ]),
    textGradient: PropTypes.bool,
    children: PropTypes.node.isRequired,
    opacity: PropTypes.number,
}

export default MTypography