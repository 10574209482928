import { createSlice } from "@reduxjs/toolkit"

const auth = createSlice({
    name: 'auth',
    initialState: {
        user: {}
    },
    reducers: {
        SET_USER(state, { payload }) {
            return {
                ...state,
                user: payload
            }
        }
    }
})

export const { SET_USER } = auth.actions
export default auth.reducer