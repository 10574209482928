import { Button } from "@mui/material"
import { styled } from "@mui/system"
import pxToRem from "assets/theme/functions/pxToRem"

export default styled(Button)(({ theme, marginright, marginbottom }) => ({
    fontSize: pxToRem(16),
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: marginright ?? theme.spacing(2),
    marginBottom: marginbottom ?? theme.spacing(2),
    minWidth: pxToRem(100)
}))