import { lazy } from "react"
import { Route } from "react-router-dom"
import Loadable from "components/Loadable"

const Login = Loadable(lazy(() => import('../views/auth/Login')))

const routes = [
    <Route key={'login'} path='/login' element={<Login />} />
]

export default routes