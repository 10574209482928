import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import translationEN from 'lang/en.json'
import translationKM from 'lang/km.json'
import { LOCALIZE } from "./constant"

i18n.use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: translationEN
            },
            km: {
                translation: translationKM
            }
        },
        lng: LOCALIZE,
        fallbackLng: 'en',

        interpolation: {
            escapeValue: false
        }
    })

export default i18n;