import { lazy } from "react";
import { Route } from "react-router-dom";
import Loadable from "components/Loadable";

const List = Loadable(lazy(() => import("../views/division/List")));
const Form = Loadable(lazy(() => import("../views/division/Form")));

const routes = [
  <Route key={"division"} path="/divisions" element={<List />} />,
  <Route key={"division.add"} path="/divisions/add" element={<Form />} />,
  <Route key={"division.edit"} path="/divisions/edit/:id" element={<Form />} />,
];

export default routes;
