import { BrowserRouter, Routes, Route } from "react-router-dom";
import auth from "./auth";
import error from "./error";
import document from "./document";
import division from "./division";
import position from "./position";
import user from "./user";
import circulationRoute from "./circulationRoute";
import userActivity from "./UserActivity";
import report from "./report";
import { AuthenticateRoute } from "./middleware";
import AppLayout from "layouts/AppLayout";

export default function AppRoute() {
  return (
    <BrowserRouter>
      <Routes>
        {auth}
        <Route
          path="/"
          element={
            <AuthenticateRoute>
              <AppLayout />
            </AuthenticateRoute>
          }
        >
          {document}
          {division}
          {position}
          {user}
          {circulationRoute}
          {userActivity}
          {report}
        </Route>
        {error}
      </Routes>
    </BrowserRouter>
  );
}
