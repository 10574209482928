import { lazy } from "react";
import { Route } from "react-router-dom";
import Loadable from "components/Loadable";

const List = Loadable(lazy(() => import("../views/userActivity/List")));

const routes = [
  <Route key={"activity"} path="/user-activities" element={<List />} />,
];

export default routes;
