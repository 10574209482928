import React, { createContext, useContext, useState } from "react"
import { merge } from "lodash"
import { LayoutSettings } from "layouts/LayoutSetting"

const SettingsContext = createContext({
    settings: LayoutSettings,
    updateSettings: () => {},
})

export const useSettings = () => useContext(SettingsContext)

export const SettingsProvider = ({ settings, children }) => {
    const [currentSettings, setCurrentSettings] = useState(
        settings || LayoutSettings
    )

    const handleUpdateSettings = (update = {}) => {
        const merged = merge({}, currentSettings, update)
        setCurrentSettings(merged)
    }

    return (
        <SettingsContext.Provider
            value={{
                settings: currentSettings,
                updateSettings: handleUpdateSettings,
            }}
        >
            {children}
        </SettingsContext.Provider>
    )
}

export default SettingsContext