import Cookies from "js-cookie"
import { useEffect } from "react"
import { Navigate, useLocation, useNavigate } from "react-router-dom"
import { INTENDED_URL, TOKEN } from "../utils/constant"

export const AuthenticateRoute = ({ children }) => {
    const token = Cookies.get(TOKEN)
    const navigation = useNavigate()
    const location = useLocation()

    useEffect(() => {
        if (token && window.location.pathname === '/login') {
            navigation('/')
        }
    })

    if (!token && window.location.pathname !== '/login') {
        Cookies.set(INTENDED_URL, location.pathname + location.search ?? '/')
        return <Navigate to='/login' />
    }

    return children
}