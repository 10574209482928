import { SET_DOCUMENT } from "."
import DocumentService from "../../requests/documentRequest"

export const getDocuments = (payload) => {
    return async (dispatch) => {
        const response = await DocumentService.getDocuments(payload)
        dispatch(SET_DOCUMENT(response.data))
        return response.data
    }
}

export const getTodoDocuments = (payload) => {
    return async (dispatch) => {
        const response = await DocumentService.getTodoDocuments(payload)
        dispatch(SET_DOCUMENT(response.data))
        return response.data
    }
}

export const getIncomingDocuments = (payload) => {
    return async (dispatch) => {
        const response = await DocumentService.getIncomingDocuments(payload)
        dispatch(SET_DOCUMENT(response.data))
        return response.data
    }
}

export const getOutgoingDocuments = (payload) => {
    return async (dispatch) => {
        const response = await DocumentService.getOutgoingDocuments(payload)
        dispatch(SET_DOCUMENT(response.data))
        return response.data
    }
}

export const updateIncomingDocument = (payload) => {
    return async () => {
        const response = await DocumentService.updateIncomingDocument(payload)
        return response.data
    }
}

export const updateOutgoingDocument = (payload) => {
    return async () => {
        const response = await DocumentService.updateOutgoingDocument(payload)
        return response.data
    }
}

export const getDocumentById = (id) => {
    return async (dispatch) => {
        const response = await DocumentService.getDocumentById(id)
        dispatch(SET_DOCUMENT(response.data))
        return response.data
    }
}

export const getPassDocumentByIds = (id) => {
    return async (dispatch) => {
        const response = await DocumentService.getPassDocumentByIds(id)
        dispatch(SET_DOCUMENT(response.data))
        return response.data
    }
}

export const getReceiveDocumentByIds = (id) => {
    return async (dispatch) => {
        const response = await DocumentService.getReceiveDocumentByIds(id)
        dispatch(SET_DOCUMENT(response.data))
        return response.data
    }
}

export const getCompleteDocumentByIds = (id) => {
    return async (dispatch) => {
        const response = await DocumentService.getCompleteDocumentByIds(id)
        dispatch(SET_DOCUMENT(response.data))
        return response.data
    }
}

export const getUnCompleteDocumentById = (id) => {
    return async (dispatch) => {
        const response = await DocumentService.getUnCompleteDocumentById(id)
        dispatch(SET_DOCUMENT(response.data))
        return response.data
    }
}

export const getDocumentDetailById = (id) => {
    return async (dispatch) => {
        const response = await DocumentService.getDocumentDetailById(id)
        dispatch(SET_DOCUMENT(response.data))
        return response.data
    }
}

export const addDocument = (payload) => {
    return async () => {
        const response = await DocumentService.addDocument(payload)
        return response.data
    }
}

export const updateDocument = (payload, id) => {
    return async () => {
        const response = await DocumentService.updateDocument(payload, id)
        return response.data
    }
}

export const updateCompleteDocument = (payload, id) => {
    return async () => {
        const response = await DocumentService.updateCompleteDocument(payload, id)
        return response.data
    }
}

export const updatePassingDocument = (payload, id) => {
    return async () => {
        const response = await DocumentService.updatePassingDocument(payload, id)
        return response.data
    }
}

export const deleteDocument = (id) => {
    return async () => {
        const response = await DocumentService.deleteDocument(id)

        return response.data
    }
}

export const getDocumentBadge = (id) => {
    return async (dispatch) => {
        const response = await DocumentService.getDocumentBadge(id)
        dispatch(SET_DOCUMENT(response.data))
        return response.data
    }
}

export const updateUnCompleteDocument = (id, payload) => {
    return async () => {
        const response = await DocumentService.updateUnCompleteDocument(id, payload)
        return response.data
    }
}

export const getDocumentForQRCode = (id) => {
    return async () => {
        const response = await DocumentService.getDocumentForQRCode(id)
        return response.data
    }
}

export const getDocumentResources = () => {
    return async () => {
        const response = await DocumentService.getDocumentResources()

        return response.data
    }
}