import { lazy } from "react";
import { Route } from "react-router-dom";
import Loadable from "components/Loadable";

const List = Loadable(lazy(() => import("../views/circulationRoute/List")));
const Form = Loadable(lazy(() => import("../views/circulationRoute/Form")));

const routes = [
  <Route
    key={"circulation_route"}
    path="/circulation-route"
    element={<List />}
  />,
  <Route
    key={"circulation_route.add"}
    path="/circulation-route/add"
    element={<Form />}
  />,
  <Route
    key={"circulation_route.edit"}
    path="/circulation-route/edit/:id"
    element={<Form />}
  />,
];

export default routes;
