import { createSlice } from "@reduxjs/toolkit"

const positions = createSlice({
    name: 'positions',
    initialState: {
        positions: {}
    },
    reducers: {
        SET_POSITIONS(state, { payload }) {
            return {
                ...state,
                positions: payload
            }
        }
    }
})

export const { SET_POSITIONS } = positions.actions
export default positions.reducer