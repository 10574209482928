import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useMediaQuery } from "@mui/material"
import { styled, useTheme } from "@mui/system"
import { useSettings } from "contexts/SettingsContext"
import ScrollBar from "react-perfect-scrollbar"
import { Outlet } from "react-router-dom"
import MBox from "components/MBox"
import LoadingSuspense from "components/LoadingSuspense"
import Cookies from "js-cookie"
import { TOKEN, SIDE_NAV_WIDTH } from "utils/constant"
import { getCurrentUser } from "stores/auth/thunk"
import SideNav from "./SideNav"
import Topbar from "./Topbar"
import { DialogError } from "components/Dialog/DialogError"
import { removeError } from '../stores/error/action'
import { echo } from "utils/common"
import reportRequest from "requests/reportRequest"
import MSnackbar from "components/MSnackbar"
import { getDocumentBadge } from "stores/document/thunk"
import { navigations } from "utils/navigations"
import { useTranslation } from "react-i18next"
import { PERMISSIONS } from "utils/permissions"


const LayoutContainer = styled(MBox)(({ width }) => ({
    height: '100vh',
    display: 'flex',
    flexGrow: '1',
    flexDirection: 'column',
    verticalAlign: 'top',
    marginLeft: width,
    position: 'relative',
    overflow: 'hidden',
    transition: 'all 0.3s ease',
}));

const StyledScrollBar = styled(ScrollBar)(() => ({
    height: '100%',
    position: 'relative',
    display: 'flex',
    flexGrow: '1',
    flexDirection: 'column',
}))

const ContentBox = styled(MBox)(() => ({
    height: '100%',
    display: 'flex',
    overflowY: 'auto',
    overflowX: 'hidden',
    flexDirection: 'column',
    justifyContent: 'space-between',
}))

const StyledContent = styled(MBox)(({theme}) => ({
    margin: '30px',
    position: 'relative',
    flexGrow: 1,
    height: 'inherit',
    [theme.breakpoints.down('lg')]: {
        margin: '15px',
    }
}))

export default function AppLayout() {
    const { settings, updateSettings } = useSettings()
    const { leftSidebar: { mode: sidenavMode, show: showSidenav } } = settings
    const token = Cookies.get(TOKEN)
    const dispatch = useDispatch()
    const {t} = useTranslation()

    useEffect(() => {
        dispatch(removeError()) //clear error at first
    }, [dispatch])

    const getSidenavWidth = () => {
        if (sidenavMode === 'full') {
            return SIDE_NAV_WIDTH
        }

        return '0px'
    }

    function checkToken() {
        if (!token && window.location.pathname !== '/login') {
            window.location = '/login'
        }
    }

    const sidenavWidth = getSidenavWidth()
    const theme = useTheme()
    const isMdScreen = useMediaQuery(theme.breakpoints.down('md'))

    const ref = useRef({ isMdScreen, settings })
    const layoutClasses = `theme-${theme.palette.mode}`

    useEffect(() => {
        checkToken()
    })

    useEffect(() => {
        let { settings } = ref.current
        let sidebarMode = settings.leftSidebar.mode
        if (settings.leftSidebar.show) {
            let mode = isMdScreen ? 'close' : sidebarMode
            updateSettings({ leftSidebar: { mode }})
        }
        // eslint-disable-next-line
    }, [isMdScreen])

    useEffect(() => {
        dispatch(getCurrentUser())
    }, [dispatch])

    //navigation
    const [navigation, setNavigation] = useState(navigations)
    useEffect(() => {
        retrieveMenu()
        // eslint-disable-next-line
    }, [])

    const retrieveMenu = async () => {
        try {
            const response = await dispatch(getDocumentBadge())
            setMenuValue(response)
        } catch (error) {
        }
    }

    const setMenuValue = (menu) => {
        if (menu) {
            let temp = [...navigations]
            temp.forEach(val => {
                if (val.name === 'Todo') {
                    val.badge = { value: menu.todo, color: 'error' }
                }
                else if (val.name === 'Outgoing') {
                    val.badge = { value: menu.outgoing, color: 'error' }
                }
                else if (val.name === 'Incoming') {
                    val.badge = { value: menu.incoming, color: 'error' }
                }
            })
            setNavigation(temp)
        }
    }

    //socket
    const { user } = useSelector((state) => state.auth)
    const [report, setReport] = useState({message: '', show: false, error: false})

    const handleDownloadReportFile = (data, fileName) => {
        // Create download link
        let type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        let _blob = new Blob([data], { type: type })
        const url = window.URL.createObjectURL(_blob);

        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;

        // Download file
        link.click();

        // Clean up 
        link.remove();
        window.URL.revokeObjectURL(url);
    }

    const handleDownloadReport = async (fileName) => {
        try {
            const response = await reportRequest.downloadReportFile(fileName)
            handleDownloadReportFile(response.data, fileName)
            setReport({
                message: t("Report {{report}} downloaded successfully!", {
                    report: fileName,
                }), 
                show: true, 
                error: false
            })
        } catch (error) {
            setReport({
                message: t("Report {{report}} downloaded failed!", {
                    report: fileName,
                }), 
                show: true,
                error: true
            })
        }
    }

    const listenChannelBroadcast = (e) => {
        let eventName = (user.user_level <= 2) ? 'admin' : user.division_id
        echo(`menus.${eventName}`, ".badge", (e) => {
            setMenuValue(e.response)
        })

        if (!user.permissions.includes(PERMISSIONS.report)) return
        echo(`download-reports-success.${user.id}`, ".reports", (e) => {
            if (e.response && e.response.file_name) {
                handleDownloadReport(e.response.file_name)
            }
        })

        echo(`download-reports-failed.${user.id}`, ".reports", (e) => {
            if (e.response && e.response.file_name) {
                setReport({
                    message: t("Report {{report}} downloaded failed!", {
                        report: e.response.file_name,
                    }), 
                    show: true,
                    error: true
                })
            }
        })
    }

    useEffect(() => {
        if (user.user_level) {
            listenChannelBroadcast()
        }
        // eslint-disable-next-line
    }, [user])

    return (
        <MBox display='flex' className={layoutClasses}>
            {showSidenav && sidenavMode !== 'close' && (
                <SideNav navigation={navigation}/>
            )}

            <LayoutContainer width={sidenavWidth}>
                {settings.topbar.show && settings.topbar.fixed && (
                    <Topbar fixed={true} className='elevation-z8' />
                )}

                {settings.perfectScrollbar && (
                    <StyledScrollBar>
                        {settings.topbar.show && !settings.topbar.fixed && (
                            <Topbar />
                        )}
                        <StyledContent>
                            <LoadingSuspense>
                                <Outlet />
                            </LoadingSuspense>
                        </StyledContent>
                    </StyledScrollBar>
                )}

                {!settings.perfectScrollbar && (
                    <ContentBox>
                        {settings.topbar.show && !settings.topbar.fixed && (
                            <Topbar />
                        )}

                        <StyledContent>
                            <LoadingSuspense>
                                <Outlet />
                            </LoadingSuspense>
                        </StyledContent>
                    </ContentBox>
                )}
            </LayoutContainer>
            <DialogError/>
            <MSnackbar
                color={!report.error ? "success" : "error"}
                icon={!report.error ? "check" : "error"}
                content={report.message}
                open={report.show}
                onClose={() => setReport({...report, show: false })}
                close={() => setReport({...report, show: false })}
            />
        </MBox>
    )
}