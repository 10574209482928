import { createSlice } from "@reduxjs/toolkit";
const circulationRoute = createSlice({
  name: "circulationRoute",
  initialState: {
    circulationRoute: {},
  },
  reducers: {
    SET_CIRCULATION_ROUTE(state, { payload }) {
      return {
        ...state,
        circulationRoute: payload,
      };
    },
  },
});

export const { SET_CIRCULATION_ROUTE } = circulationRoute.actions;
export default circulationRoute.reducer;
