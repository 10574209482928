import { createSlice } from "@reduxjs/toolkit"

const documents = createSlice({
    name: 'documents',
    initialState: {
        documents: {}
    },
    reducers: {
        SET_DOCUMENT(state, { payload }) {
            return {
                ...state,
                documents: payload
            }
        }
    }
})

export const { SET_DOCUMENT } = documents.actions
export default documents.reducer