import Typography from "@mui/material/Typography"
import { styled } from "@mui/material/styles"

export default styled(Typography)(({ theme, ownerState }) => {
    const { palette, typography, functions } = theme
    const { color, textTransform, verticalAlign, fontWeight, opacity, textGradient } = ownerState

    const { gradients, transparent } = palette
    const { fontWeightLight, fontWeightRegular, fontWeightMedium } = typography
    const { linearGradient } = functions

    const fontWeights = {
        light: fontWeightLight,
        regular: fontWeightRegular,
        medium: fontWeightMedium,
        bold: fontWeightMedium,
    }

    const gradientStyles = () => ({
        backgroundImage:
            color !== "inherit" && color !== "text" && color !== "white" && gradients[color]
                ? linearGradient(gradients[color].main, gradients[color].state)
                : linearGradient(gradients.dark.main, gradients.dark.state),
        display: "inline-block",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: transparent.main,
        position: "relative",
        zIndex: 1,
    })

    let colorValue = color === "inherit" || !palette[color] ? "inherit" : palette[color].main

    return {
        opacity,
        textTransform,
        verticalAlign,
        textDecoration: 'none',
        color: colorValue,
        fontWeight: fontWeights[fontWeight] && fontWeights[fontWeight],
        ...(textGradient && gradientStyles())
    }
})