import { configureStore } from "@reduxjs/toolkit";
import auth from "./auth";
import divisions from "./division";
import positions from "./position";
import documents from "./document";
import circulationRoute from "./circulationRoute";
import { combineReducers } from 'redux'
import { errorSlice } from './error/slice'

export const rootReducer = combineReducers({
  error: errorSlice.reducer,
  auth,
  divisions,
  documents,
  positions,
  circulationRoute
  //other reducers here
})

const store = configureStore({
  reducer: rootReducer,
  devTools: true,
});

export default store;
