import BaseRequest from "./baseRequest"

class AuthRequest extends BaseRequest {
    login(payload) {
        return this.performRequest(BaseRequest.METHOD_POST, 'login', payload)
    }

    resend(payload) {
        return this.performRequest(BaseRequest.METHOD_POST, 'resend', payload)
    }

    getCurrentUser() {
        return this.performRequest(BaseRequest.METHOD_GET, 'me')
    }

    updateProfile(payload) {
        let form = new FormData()

        for (const [key, value] of Object.entries(payload)) {
            if (key && value) {
                form.append(key, value)
            }
        }

        return this.performRequest(BaseRequest.METHOD_PATCH, 'me', form)
    }

    updatePassword(payload) {
        let form = new FormData()

        for (const [key, value] of Object.entries(payload)) {
            if (key && value) {
                form.append(key, value)
            }
        }

        return this.performRequest(BaseRequest.METHOD_PATCH, 'update-password', form)
    }

    logout() {
        return this.performRequest(BaseRequest.METHOD_POST, 'logout')
    }
}

const authRequest = new AuthRequest('auth')
export default authRequest