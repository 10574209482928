import { lazy } from "react"
import { Route } from "react-router-dom"
import Loadable from "components/Loadable"

const List = Loadable(lazy(() => import('../views/user/List')))
const Form = Loadable(lazy(() => import('../views/user/Form')))
const Profile = Loadable(lazy(() => import('../views/user/Profile')))
const ChangePassword = Loadable(lazy(() => import('../views/user/ChangePassword')))

const routes = [
    <Route key={'user'} path='/users' element={<List />} />,
    <Route key={'user.add'} path='/users/add' element={<Form />} />,
    <Route key={'user.edit'} path='/users/edit/:id' element={<Form />} />,
    <Route key={'user.profile'} path='/profile' element={<Profile />} />,
    <Route key={'user.change_password'} path='/change-password' element={<ChangePassword /> } />,
]

export default routes