import { lazy } from "react"
import { Route } from "react-router-dom"
import Loadable from "components/Loadable"

const Report = Loadable(lazy(() => import('../views/report')))

const routes = [
    <Route key={'reports'} path='/reports' element={<Report />} />,
]

export default routes