import Cookies from 'js-cookie'
import { TOKEN, USER_PERMISSIONS } from './constant'
import laravelEcho from 'services/laravel-echo'
import dayjs from './dayjs'

export const formatDate = (date) => {
    if (!date) return null
    return dayjs(date).format('DD/MM/YYYY')
}

export const formatDatetime = (datetime) => {
    if (!datetime) return null
    return dayjs(datetime).format('DD/MM/YYYY HH:mm:ss')
}

export const dateDiff = (date) => {
    let now = dayjs()
    let dateDiff = dayjs(date)

    return dateDiff.diff(now, 'days')
}

export const isEmpty = (obj) => {
    return obj && Object.keys(obj).length === 0
}

export const clearToken = () => {
    if (window.location !== '/login') {
        window.location = '/login'
    }

    return Cookies.remove(TOKEN)
}

export const echo = (chanelName, eventName, callback) => {
    if (process.env.REACT_APP_WEBSOCKET_ENABLE !== 'true') {
        return;
    }

    laravelEcho.channel(chanelName).listen(eventName, (response) => {
        callback({ echo: laravelEcho, response })
    })
}

export const unEcho = (chanelName, eventName) => {
    if (process.env.REACT_APP_WEBSOCKET_ENABLE !== 'true') {
        return;
    }

    laravelEcho.channel(chanelName).stopListening(eventName)
}

export const userCan = (permission) => {
    var userPermissions = Cookies.get(USER_PERMISSIONS)

    if (userPermissions === undefined || userPermissions === null) {
        return true
    }

    userPermissions = userPermissions.split(',')

    var isAll = userPermissions.indexOf('*') === -1 ? false : true
    var isPermission = userPermissions.valueOf().indexOf(permission) === -1 ? false : true

    return isAll || isPermission
}

export const checkPermissions = (...permissions) => {
    let hasPermission = true

    permissions.forEach(function (permission) {
        if (! userCan(permission)) {
            hasPermission = false
            return
        }
    })

    return hasPermission
}

export const checkAnyPermission = (...permissions) => {
    let hasPermission = false

    permissions.forEach(function (permission) {
        if (userCan(permission)) {
            hasPermission = true
            return
        }
    })

    return hasPermission
}