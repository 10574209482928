import http from "./axios"

export default class BaseRequest {
    static get METHOD_GET() {
        return 'GET'
    }

    static get METHOD_POST() {
        return 'POST'
    }

    static get METHOD_PUT() {
        return 'PUT'
    }

    static get METHOD_PATCH() {
        return 'PATCH'
    }

    static get METHOD_DELETE() {
        return 'DELETE'
    }

    constructor(prefix) {
        this.prefix = prefix
    }

    index(payload = {}) {
        return this.performRequest(BaseRequest.METHOD_GET, '', payload)
    }

    find(id) {
        return this.performRequest(BaseRequest.METHOD_GET, `${id}/`)
    }

    store(payload) {
        return this.performRequest(BaseRequest.METHOD_POST, `add/`, payload)
    }

    update(id, payload) {
        return this.performRequest(BaseRequest.METHOD_PUT, `update/${id}`, payload)
    }

    destroy(id, payload={}) {
        return this.performRequest(BaseRequest.METHOD_DELETE, `delete/${id}`, payload)
    }

    performRequest(method, endPoint, payload = {}, headers = {}, props = {}) {
        let url = `${this.prefix}${endPoint ? '/' + endPoint : ''}`
        let payloadKey = method.toUpperCase() === BaseRequest.METHOD_GET ? 'params' : 'data'

        let options = {
            url,
            method,
            headers,
            [payloadKey]: payload,
            ...props
        }

        return http(options)
    }

    performDownload(url, data, requestOptions) {
        return this.performRequest(
            BaseRequest.METHOD_POST,
            url,
            data,
            {},
            {
                responseType: 'blob',
                ...requestOptions
            }
        )
    }
}