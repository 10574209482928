export const PERMISSIONS = {
    document: {
        new: 'new_document',
        update: 'update_document',
        pass: 'pass_document',
        cancel: 'cancel_document',
        receive: 'receive_document',
        complete: 'complete_document',
        delete: 'delete_document',
        view: 'view_document',
        view_tracking: 'view_document_tracking'
    },
    user: {
        new: 'new_user',
        update: 'update_user',
        delete: 'delete_user'
    },
    division: {
        new: 'new_division',
        update: 'update_division',
        delete: 'delete_division'
    },
    position: {
        new: 'new_position',
        update: 'update_position',
        delete: 'delete_position'
    },
    circulation_route: {
        new: 'new_route',
        update: 'update_route',
        delete: 'delete_route'
    },
    activities: 'view_user_activities',
    report: 'report'
}