export default class BaseReponse {
    constructor({ data, statusCode }) {
        this._data = data
        this._statusCode = statusCode
    }

    getData() {
        return this._data
    }

    statusOk() {
        return this._statusCode === 200
    }

    statusUnauthorize() {
        return this._statusCode === 401
    }

    statusNotFound() {
        return this._statusCode === 404
    }

    statusForbidden() {
        return this._statusCode === 403
    }

    statusUnprocessableEntity() {
        return this._statusCode === 422
    }

    statusConflictError() {
        return this._statusCode === 409
    }

    statusInternalServerError() {
        return this._statusCode === 500
    }
}