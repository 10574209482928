import { lazy } from "react"
import { Route } from "react-router-dom"
import Loadable from "components/Loadable"

const List = Loadable(lazy(() => import('../views/position/List')))
const Form = Loadable(lazy(() => import('../views/position/Form')))

const routes = [
    <Route key={'position'} path='/positions' element={<List />} />,
    <Route key={'position.add'} path='/positions/add' element={<Form />} />,
    <Route key={'position.edit'} path='/positions/edit/:id' element={<Form />} />,
]

export default routes