import BaseRequest from "./baseRequest";

class ReportRequest extends BaseRequest {
    downloadReports(payload) {
        return this.performRequest(BaseRequest.METHOD_POST, 'get-reports', payload)
    }

    downloadReportFile(fileName) {
        return this.performRequest(
            BaseRequest.METHOD_GET,
            `download/${fileName}`,
            {},
            {},
            {
                responseType: 'blob'
            }
        )
    }
}
const reportRequest = new ReportRequest('report')
export default reportRequest