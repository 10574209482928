import axios from "axios"
import Cookies from "js-cookie"
import { TOKEN } from "../utils/constant"
import BaseReponse from "../responses/baseReponse"
import { errorActions } from '../stores/error/action'
import store from '../stores/index'

const http = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: {
        'Content-Type': 'application/json'
    }
})

http.interceptors.request.use(
    (config) => {
        const token = Cookies.get(TOKEN)
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`
        }
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

http.interceptors.response.use(
    (response) => {
        return response
    },
    (error) => {
        const response = new BaseReponse({
            data: error?.response?.data,
            statusCode: error?.response?.status
        })

        if (response.statusUnprocessableEntity()) {
            return Promise.reject(response.getData())
        } else if (response.statusUnauthorize()) {
            Cookies.remove(TOKEN)
            window.location = '/login'
            return Promise.reject(response.getData())
        } else if (response.statusForbidden()) {
            return Promise.reject(response.getData())
        } else if (response.statusNotFound()) {
            store.dispatch(errorActions.showError({ title: 'Error', message: response.getData().message }))
            return Promise.reject(response.getData())
        } else if (response.statusInternalServerError()) {
            return Promise.reject(response.getData())
        } else if (response.statusConflictError()) {
            store.dispatch(errorActions.showError({ title: 'Error', message: response.getData().message }))
            return Promise.reject(response.getData())
        }

        return Promise.reject(error)
    }
)

export default http