import { Trans } from "react-i18next"
import MBox from "components/MBox"
import MTypography from "components/MTypography"

export default function Brand({ children }) {
    return (
        <MBox
            display='flex'
            color='white'
            justifyContent='center'
            pt={3}
            pb={3}
            px={3}
        >
            <MBox display='flex'>
                <MTypography variant='span' color='white' textAlign='center' sx={{ fontSize: '22px' }}>
                    <Trans>Document Tracking<br/>System</Trans>
                </MTypography>
            </MBox>
            <MBox className='sidenavHoverShow'>
                {children || null}
            </MBox>
        </MBox>
    )
}