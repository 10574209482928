import { styled } from '@mui/system'
import { Fragment } from 'react'
import ScrollBar from 'react-perfect-scrollbar'
import MBox from "components/MBox"
import Brand from "./Brand"
import { useSettings } from 'contexts/SettingsContext'
import VerticalNav from './VerticalNav/VerticalNav'
import { SIDE_NAV_WIDTH } from 'utils/constant'
import { IconButton, useMediaQuery } from '@mui/material'
import { Close } from '@mui/icons-material'
import theme from 'assets/theme'

const SidebarNavRoot = styled(MBox)(({ theme }) => ({
    background: theme.palette['dark'].main,
    boxShadow: theme.boxShadows.colored['dark'],
    color: theme.palette.white.main,
    position: 'fixed',
    top: 0,
    left: 0,
    height: '100vh',
    width: SIDE_NAV_WIDTH,
    zIndex: 111,
    overflow: 'hidden',
    transition: 'all 250ms ease-in-out',
    '&:hover': {
        width: SIDE_NAV_WIDTH,
        '& .sidenavHoverShow': {
            display: 'block',
        },
        '& .compactNavItem': {
            width: '100%',
            maxWidth: '100%',
            '& .nav-bullet': {
                display: 'block',
            },
            '& .nav-bullet-text': {
                display: 'none',
            }
        }
    }
}))

const NavListBox = styled(MBox)(({ theme }) => ({
    height: '100%',
    color: theme.palette.white.main,
    display: 'flex',
    flexDirection: 'column',
}))

const StyledScrollBar = styled(ScrollBar)(() => ({
    paddingLeft: '1rem',
    paddingRight: '1rem',
    position: 'relative',
}))

const SideNavMobile = styled('div')(({ theme }) => ({
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: '100vw',
    background: 'rgba(0, 0, 0, 0.54)',
    zIndex: -1,
    [theme.breakpoints.up('lg')]: { display: 'none' },
}))

export default function SideNav({ navigation, children }) {
    const { settings, updateSettings } = useSettings()

    const updateSidebarMode = (sidebarSettings) => {
        updateSettings({
            ...settings,
            leftSidebar: {
                ...sidebarSettings,
            }
        })
    }

    const isLgScreen = useMediaQuery(theme.breakpoints.down('lg'))

    return (
        <SidebarNavRoot>
            <NavListBox>
                {
                    isLgScreen && (
                        <MBox sx={{ position: 'absolute', right: '0' }}>
                            <IconButton color="white" size="large" onClick={() => updateSidebarMode({ mode: 'close' })}>
                                <Close />
                            </IconButton>
                        </MBox>)
                }
                <Brand />
                <Fragment>
                    <StyledScrollBar options={{ suppressScrollX: true }}>
                        {children}
                        <VerticalNav items={navigation} />
                    </StyledScrollBar>

                    <SideNavMobile onClick={() => updateSidebarMode({ mode: 'close' })} />
                </Fragment>
            </NavListBox>
        </SidebarNavRoot>
    )
}