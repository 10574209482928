import { PERMISSIONS } from "./permissions"

export const navigations = [
  {
    name: "Documents",
    path: "/"
  },
  {
    name: "Todo",
    path: "document/todo"
  },
  {
    name: "Outgoing",
    path: "document/outgoing"
  },
  {
    name: "Incoming",
    path: "document/incoming",
    permissions: [
      PERMISSIONS.document.receive
    ]
  },
  {
    name: "Divisions",
    path: "/divisions",
    permissions: [
      PERMISSIONS.division.new,
      PERMISSIONS.division.update,
      PERMISSIONS.division.delete
    ]
  },
  {
    name: "Positions",
    path: "/positions",
    permissions: [
      PERMISSIONS.position.new,
      PERMISSIONS.position.update,
      PERMISSIONS.position.delete
    ]
  },
  {
    name: "Circulation Routes",
    path: "/circulation-route",
    permissions: [
      PERMISSIONS.circulation_route.new,
      PERMISSIONS.circulation_route.update,
      PERMISSIONS.circulation_route.delete
    ]
  },
  {
    name: "Users",
    path: "/users",
    permissions: [
      PERMISSIONS.user.new,
      PERMISSIONS.user.update,
      PERMISSIONS.user.delete
    ]
  },
  {
    name: "User Activities",
    path: "/user-activities",
    permissions: [
      PERMISSIONS.activities
    ]
  },
  {
    name: "Reports",
    path: "/reports",
    permissions: [
      PERMISSIONS.report
    ],
  },
];
