import { Badge, Box, ButtonBase, Icon, styled } from "@mui/material"
import React from "react"
import { NavLink, useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useSettings } from "contexts/SettingsContext"
import MTypography from "components/MTypography"
import { checkAnyPermission } from "utils/common"
import VerticalNavExpansionPanel from "./VerticalNavExpansionPanel"
import { USER_PERMISSIONS } from "utils/constant"
import Cookies from "js-cookie"

const ListLabel = styled(MTypography)(({ theme }) => ({
    color: theme.palette.white.main,
    fontSize: '16px',
    marginTop: '20px',
    marginLeft: '15px',
    marginBottom: '10px',
    textTransform: 'uppercase',
}))

const ExtAndIntCommon = {
    display: 'flex',
    overflow: 'hidden',
    borderRadius: '4px',
    height: 44,
    whiteSpace: 'pre',
    marginBottom: '8px',
    textDecoration: 'none',
    justifyContent: 'space-between',
    transition: 'all 150ms ease-in',
    '&:hover': { background: 'rgba(255, 255, 255, 0.2)' },
    '& .icon': {
        fontSize: '20px',
        paddingLeft: '16px',
        paddingRight: '16px',
        verticalAlign: 'middle',
    }
}

const ExternalLink = styled('a')(({ theme }) => ({
    ...ExtAndIntCommon,
    color: theme.palette.white.main,
}))

const InternalLink = styled(Box)(({ theme }) => ({
    '& a': {
        ...ExtAndIntCommon,
        color: theme.palette.white.main,
    },
    '& .navItemActive': {
        backgroundImage: `${theme.functions.linearGradient(
            theme.functions.rgba(theme.palette.gradients.info.main, 0.6),
            theme.functions.rgba(theme.palette.gradients.info.state, 0.6)
        )}`,
        '&:hover': {
            backgroundImage: `${theme.functions.linearGradient(
                theme.functions.rgba(theme.palette.gradients.info.main, 0.6),
                theme.functions.rgba(theme.palette.gradients.info.state, 0.6)
            )}`,
        }
    }
}))

const StyledText = styled(MTypography)(() => ({
    color: 'white',
    fontSize: '1.1rem',
    paddingLeft: '1rem'
}))

export default function VerticalNav({ items }) {
    const { t } = useTranslation()
    const { settings } = useSettings()
    const { mode } = settings.leftSidebar
    const navigate = useNavigate()
    var userPermissions = Cookies.get(USER_PERMISSIONS)

    const renderLevels = (data) => {
        return data.map((item, index) => {
            if (item.type === 'label')
                return (
                    <ListLabel key={index} mode={mode} className='sidenavHoverShow'>
                        {t(item.label)}
                    </ListLabel>
                )

            if (item.children) {
                return (
                    <VerticalNavExpansionPanel mode={mode} item={item} key={index}>
                        {renderLevels(item.children)}
                    </VerticalNavExpansionPanel>
                )
            } else if (item.type === 'extLink') {
                return (
                    <ExternalLink
                        key={index}
                        href={item.path}
                        rel='noopener noreferrer'
                        target='_blank'
                    >
                        <ButtonBase key={item.name} name='child' sx={{ width: '100%' }}>
                            {(() => {
                                if (item.icon) {
                                    return <Icon className='icon' sx={{ width: 36 }}>{item.icon}</Icon>
                                }
                            })()}
                            <StyledText mode={mode} className='sidenavHoverShow'>
                                {t(item.name)}
                            </StyledText>
                            <Box mx='auto'></Box>
                            {item.badge && (
                                <Badge className='sidenavHoverShow' color={item.badge.color} badgeContent={item.badge.value} sx={{ right: '25px' }} />
                            )}
                        </ButtonBase>
                    </ExternalLink>
                )
            } else {
                return (
                    <InternalLink key={index}>
                        {(item.permissions === undefined ||
                            item.permissions === null ||
                            (item.permissions !== undefined &&
                                item.permissions !== null &&
                                checkAnyPermission(...item.permissions)
                            )
                        ) && (
                                <NavLink
                                    to={item.path}
                                    className={({ isActive }) => isActive ? 'navItemActive' : ''}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        navigate(item.path, { state: Math.random().toString(36).slice(5) });
                                    }}
                                >
                                    <ButtonBase key={item.name} name='child' sx={{ width: '100%' }}>
                                        {(() => {
                                            if (item.icon) {
                                                return <Icon className='icon' sx={{ width: 36 }}>{item.icon}</Icon>
                                            }
                                        })()}
                                        <StyledText mode={mode} className='sidenavHoverShow'>
                                            {t(item.name)}
                                        </StyledText>

                                        <Box mx='auto' />

                                        {item.badge && (
                                            <Badge className='sidenavHoverShow' color={item.badge.color} badgeContent={item.badge.value} sx={{ right: '25px' }} />
                                        )}
                                    </ButtonBase>
                                </NavLink>
                            )}
                    </InternalLink>
                )
            }
        })
    }

    return <div className="navigation">{userPermissions && renderLevels(items)}</div>
}