import PropTypes from "prop-types"
import Snackbar from "@mui/material/Snackbar"
import IconButton from "@mui/material/IconButton"
import Icon from "@mui/material/Icon"
import Fade from "@mui/material/Fade"

import MBox from "components/MBox"
import MTypography from "components/MTypography"
import MSnackbarRoot from "./MSnackbarRoot"

function MSnackbar({ color, icon, content, close, bgWhite, ...rest }) {

    let contentColor

    if (bgWhite) {
        contentColor = 'dark'
    } else {
        contentColor = 'white'
    }

    return (
        <Snackbar
            TransitionComponent={Fade}
            autoHideDuration={3000}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            {...rest}
            action={
                <IconButton size="small" aria-label="close" color="inherit" onClick={close}>
                    <Icon fontSize="small">close</Icon>
                </IconButton>
            }
        >
            <MBox
                variant={bgWhite ? 'contained' : 'gradient'}
                bgColor={bgWhite ? 'white' : color}
                minWidth='21.875rem'
                maxWidth='100%'
                shadow='md'
                borderRadius='md'
                p={1}
            >
                <MBox
                    display= 'flex'
                    justifyContent='space-between'
                    alignItems='center'
                    color='dark'
                    p={1.5}
                >
                    <MBox
                        display='flex'
                        alignItems='center'
                        lineHeight={0}
                    >
                        <MSnackbarRoot
                            fontSize="medium"
                            ownerState={{ color, bgWhite }}
                        >
                            {icon}
                        </MSnackbarRoot>
                        <MTypography
                            variant='button'
                            fontWeight='medium'
                            color={contentColor}
                            textGradient={bgWhite}
                            fontSize='20px'
                        >
                            {content}
                        </MTypography>
                    </MBox>
                    <MBox
                        display="flex"
                        alignItems="center"
                        lineHeight={0}
                    >
                        <Icon
                            sx={{
                                color: ({ palette: { dark, white } }) =>
                                (bgWhite) ? dark.main : white.main,
                                fontWeight: ({ typography: { fontWeightMedium } }) => fontWeightMedium,
                                cursor: "pointer",
                                marginLeft: 2,
                                transform: "translateY(-1px)",
                            }}
                            onClick={close}
                        >
                            close
                        </Icon>
                    </MBox>
                </MBox>
            </MBox>
        </Snackbar>
    )
}

MSnackbar.defaultProps = {
    bgWhite: false,
    color: 'info',
}

MSnackbar.propTypes = {
    color: PropTypes.oneOf([
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "dark",
        "light",
    ]),
    icon: PropTypes.node,
    content: PropTypes.node.isRequired,
    close: PropTypes.func.isRequired,
    bgWhite: PropTypes.bool,
}

export default MSnackbar