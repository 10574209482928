//websocket
import Echo from 'laravel-echo'
window.Pusher = require('pusher-js')

const laravelEcho = new Echo({
  broadcaster: 'pusher',
  key: process.env.REACT_APP_WEBSOCKET_KEY,
  cluster:process.env.REACT_APP_WEBSOCKET_CLUSTER,
  wsHost: process.env.REACT_APP_WEBSOCKET_SERVER,
  wsPort: process.env.REACT_APP_WEBSOCKET_PORT,
  encrypted: false,
  forceTLS: false,
  disableStats: true
})

export default laravelEcho