import { Menu } from "@mui/material"
import { styled } from "@mui/system"
import React, { Fragment } from "react"
import MBox from "./MBox"

const MenuButton = styled(MBox)(({ theme }) => ({
    display: 'inline-block',
    color: theme.palette.dark.main,
    '& div:hover': {
        backgroundColor: theme.functions.rgba(theme.palette.black.main, 0.04)
    }
}))

const DropdownMenu = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null)
    const children = React.Children.toArray(props.children)
    let { shouldCloseOnItemClick = true, horizontalPosition = 'left' } = props

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    return (
        <Fragment>
            <MenuButton onClick={handleClick}>{props.menuButton}</MenuButton>
            <Menu
                elevation={8}
                anchorEl={anchorEl}
                open={!!anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: horizontalPosition,
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: horizontalPosition,
                }}
            >
                {children.map((child, index) => (
                    <div onClick={shouldCloseOnItemClick ? handleClose : () => {}} key={index}>
                        {child}
                    </div>
                ))}
            </Menu>
        </Fragment>
    )
}

export default DropdownMenu