import BaseRequest from "./baseRequest"

class DocumentRequest extends BaseRequest {
    getDocuments(payload) {
        return this.performRequest(BaseRequest.METHOD_GET, '', payload)
    }

    getTodoDocuments(payload) {
        return this.performRequest(BaseRequest.METHOD_GET, 'todo', payload)
    }

    getIncomingDocuments(payload) {
        return this.performRequest(BaseRequest.METHOD_GET, 'incoming', payload)
    }

    getOutgoingDocuments(payload) {
        return this.performRequest(BaseRequest.METHOD_GET, 'outgoing', payload)
    }

    updateIncomingDocument(payload) {
        return this.performRequest(BaseRequest.METHOD_PUT, 'incoming', payload)
    }

    updateOutgoingDocument(payload) {
        return this.performRequest(BaseRequest.METHOD_PUT, 'outgoing', payload)
    }

    getDocumentById(id) {
        return this.performRequest(BaseRequest.METHOD_GET, id)
    }

    getPassDocumentByIds(ids) {
        return this.performRequest(BaseRequest.METHOD_GET, `pass/${ids}`)
    }

    getReceiveDocumentByIds(ids) {
        return this.performRequest(BaseRequest.METHOD_GET, `receive/${ids}`)
    }

    getCompleteDocumentByIds(ids) {
        return this.performRequest(BaseRequest.METHOD_GET, `complete/${ids}`)
    }

    getUnCompleteDocumentById(id) {
        return this.performRequest(BaseRequest.METHOD_GET, `un-complete/${id}`)
    }

    getDocumentDetailById(id) {
        return this.performRequest(BaseRequest.METHOD_GET, `detail/${id}`)
    }

    addDocument(payload) {
        return this.performRequest(BaseRequest.METHOD_POST, '', payload)
    }

    updateDocument(payload, id) {
        return this.performRequest(BaseRequest.METHOD_PUT, id, payload)
    }

    updateCompleteDocument(payload) {
        return this.performRequest(BaseRequest.METHOD_PUT, `complete`, payload)
    }

    updatePassingDocument(payload) {
        return this.performRequest(BaseRequest.METHOD_PUT, `passing`, payload)
    }

    deleteDocument(id) {
        return this.performRequest(BaseRequest.METHOD_DELETE, id)
    }

    getDocumentBadge(payload) {
        return this.performRequest(BaseRequest.METHOD_GET, 'badge', payload)
    }

    updateUnCompleteDocument(id, payload) {
        return this.performRequest(BaseRequest.METHOD_PUT, `un-complete/${id}`, payload)
    }

    getDocumentForQRCode(id) {
        return this.performRequest(BaseRequest.METHOD_GET, `qr-code/${id}`)
    }

    getDocumentResources() {
        return this.performRequest(BaseRequest.METHOD_GET, 'document-resources')
    }
}

const documentRequest = new DocumentRequest('documents')
export default documentRequest