import { ButtonBase, Icon } from "@mui/material"
import { styled } from '@mui/system'
import { useCallback, useEffect, useRef, useState } from "react"
import { useLocation } from "react-router-dom"
import clsx from "clsx"
import MBox from "components/MBox"

const NavExpandRoot = styled('div')(() => ({
    '& .expandIcon': {
        transition: 'transform 0.3s cubic-bezier(0, 0, 0.2, 1) 0ms',
        transform: 'rotate(90deg)',
    },
    '& .collapseIcon': {
        transition: 'transform 0.3s cubic-bezier(0, 0, 0.2, 1) 0ms',
        transform: 'rotate(0deg)',
    },
    '& .expansion-panel': {
        overflow: 'hidden',
        transition: 'max-height 0.3s cubic-bezier(0, 0, 0.2, 1)',
    },
    '&.compactNavItem': {
        width: 44,
        overflow: 'hidden',
        justifyContent: 'center !important',
        '& .itemText': { display: 'none' },
        '& .itemIcon': { display: 'none' },
    },
}))

const BaseButton = styled(ButtonBase)(({ theme }) => ({
    height: 44,
    width: '100%',
    whiteSpace: 'pre',
    overflow: 'hidden',
    paddingRight: '16px',
    borderRadius: '4px',
    marginBottom: '8px',
    display: 'flex',
    justifyContent: 'space-between !important',
    color: theme.palette.white.main,
    '&:hover': { background: 'rgba(255, 255, 255, 0.2)' },
    '& .icon': {
        width: 36,
        fontSize: '18px',
        paddingLeft: '16px',
        paddingRight: '16px',
        verticalAlign: 'middle',
    },
}))

const ItemText = styled('span')(() => ({
    fontSize: '0.875rem',
    paddingLeft: '0.8rem',
    verticalAlign: 'middle',
}));

const BadgeValue = styled('div')(() => ({
    padding: '1px 4px',
    overflow: 'hidden',
    borderRadius: '300px',
}));

const Submenu = styled('div')(() => ({
    '& a': {
        paddingLeft: '20px',
    }
}))

export default function VerticalNavExpansionPanel({ item, children, mode }) {
    const [collapsed, setCollapsed] = useState(true)
    const elementRef = useRef(null)
    const componentHeight = useRef(0)
    const { pathname } = useLocation()
    const { name, icon, badge } = item

    const handleClick = () => {
        componentHeight.current = 0
        calculateHeight(elementRef.current)
        setCollapsed(!collapsed)
    }

    const calculateHeight = useCallback((node) => {
        if (node.name !== 'child') {
            for (let child of node.children) {
                calculateHeight(child)
            }
        }

        if (node.name === 'child') componentHeight.current += node.scrollHeight
        else componentHeight.current+= 44 //44 is node height
        return
    }, [])

    useEffect(() => {
        if (!elementRef) return

        calculateHeight(elementRef.current)

        //Open dropdown if child is active
        for (let child of elementRef.current.children) {
            if (child.getAttribute('href') === pathname) {
                setCollapsed(false)
            }
        }
    }, [pathname, calculateHeight])

    return (
        <NavExpandRoot>
            <BaseButton
                className={clsx({
                    'has-submenu': true,
                    open: !collapsed,
                })}
                onClick={handleClick}
            >
                <MBox display='flex' color='white' alignItems='center'>
                    {icon && <Icon className='icon'>{icon}</Icon>}
                    <ItemText className="sidenavHoverShow">{name}</ItemText>
                </MBox>

                {badge && <BadgeValue className="sidenavHoverShow itemIcon">{badge.value}</BadgeValue>}

                <div
                    className={clsx({
                        sidenavHoverShow: true,
                        collapseIcon: collapsed,
                        expandIcon: !collapsed,
                    })}
                >
                    <Icon fontSize="small" sx={{ verticalAlign: 'middle' }}>chevron_right</Icon>
                </div>
            </BaseButton>

            <Submenu
                ref={elementRef}
                className='expansion-panel submenu'
                style={collapsed ? { maxHeight: '0px' } : { maxHeight: componentHeight.current + 'px' }}
            >
                {children}
            </Submenu>
        </NavExpandRoot>
    )
}