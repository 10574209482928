import { Avatar, Icon, IconButton, MenuItem, useMediaQuery } from "@mui/material"
import { styled, useTheme } from "@mui/system"
import React from "react"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from "react-i18next"
import { logout } from "stores/auth/thunk"
import MBox from "components/MBox"
import MTypography from "components/MTypography"
import { useSettings } from "contexts/SettingsContext"
import DropdownMenu from "components/DropdownMenu"
import { TOP_BAR_HEIGHT } from "utils/constant"

const StyledIconButton = styled(IconButton)(({ theme }) => ({
    color: theme.palette.dark.main,
    '&:hover': {
        background: 'rgba(0, 0, 0, 0.04)',
    }
}))

const TopbarRoot = styled('div')(() => ({
    top: 0,
    zIndex: 96,
    transition: 'all 0.3s ease',
    boxShadow: '0px 5px 5px -3px rgba(0, 0, 0, 0.06),0px 8px 10px 1px rgba(0, 0, 0, 0.042),0px 3px 14px 2px rgba(0, 0, 0, 0.036)',
    height: TOP_BAR_HEIGHT,
}))

const TopbarContainer = styled(MBox)(({ theme }) => ({
    padding: '8px',
    paddingLeft: 18,
    paddingRight: 20,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: theme.palette.white.main,
    [theme.breakpoints.down('sm')]: {
        paddingLeft: 16,
        paddingRight: 16,
    },
    [theme.breakpoints.down('xs')]: {
        paddingLeft: 14,
        paddingRight: 16,
    }
}))

const UserMenu = styled(MBox)(() => ({
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    borderRadius: 24,
    padding: 4,
    '& span': { margin: '0 16px'},
}))

const AvatarBox = styled(Avatar)(({ theme }) => ({
    color: theme.palette.white.main,
    background: 'rgba(189, 189, 189)',
    cursor: 'pointer',
}))

const StyledWelcome = styled(MTypography)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        maxWidth: '220px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    }
}))

const StyledItem = styled(MenuItem)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    minWidth: 185,
    '& a': {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        TextDecoder: 'none',
    },
    '& span': { marginRight: '10px', color: theme.palette.dark.main },
}))

const StyledLink = styled(Link)(() => ({
    textDecoration: 'none',
}))

export default function Topbar() {
    const { t } = useTranslation()
    const theme = useTheme()
    const dispatch = useDispatch()
    const { user } = useSelector((state) => state.auth)
    const { settings, updateSettings } = useSettings()
    const isMdScreen = useMediaQuery(theme.breakpoints.down('md'))

    const updateSidebarMode = (sidebarSettings) => {
        updateSettings({
            leftSidebar: { ...sidebarSettings }
        })
    }

    const handleSidebarToggle = () => {
        let mode
        if (isMdScreen) {
            mode = settings.leftSidebar.mode === 'close' ? 'mobile' : 'close'
        } else {
            mode = settings.leftSidebar.mode === 'full' ? 'close' : 'full'
        }
        updateSidebarMode({ mode })
    }

    const handleLogout = () => {
        dispatch(logout)
    }

    return (
        <TopbarRoot>
            <TopbarContainer>
                <MBox display='flex'>
                    <StyledIconButton onClick={handleSidebarToggle}>
                        <Icon>menu</Icon>
                    </StyledIconButton>
                </MBox>

                <MBox display='flex' alignItems='center'>
                    <DropdownMenu
                        menuButton={
                            <UserMenu>
                                <StyledWelcome variant='span'>
                                    {t('Welcome')} <strong>{user.name}</strong>
                                </StyledWelcome>
                                <AvatarBox />
                            </UserMenu>
                        }
                    >
                        <StyledItem>
                            <StyledLink to='/profile'>
                                <Icon> person </Icon>
                                <MTypography variant='span' sx={{ lineHeight: '24px' }}> {t('Profile')} </MTypography>
                            </StyledLink>
                        </StyledItem>

                        <StyledItem>
                            <StyledLink to='/change-password'>
                                <Icon> lock_outline </Icon>
                                <MTypography variant='span' sx={{ lineHeight: '24px' }}> {t('Change Password')} </MTypography>
                            </StyledLink>
                        </StyledItem>

                        <StyledItem onClick={handleLogout}>
                            <Icon> power_settings_new </Icon>
                            <MTypography variant='span' sx={{ lineHeight: '24px' }}> {t('Logout')} </MTypography>
                        </StyledItem>
                    </DropdownMenu>
                </MBox>
            </TopbarContainer>
        </TopbarRoot>
    )
}