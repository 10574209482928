import { StyledEngineProvider } from "@mui/styled-engine";
import "perfect-scrollbar/css/perfect-scrollbar.css";

import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'

import './utils/i18n'

const container = document.getElementById("root");

createRoot(container).render(
  <StyledEngineProvider injectFirst>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </StyledEngineProvider>
);
