import { lazy } from "react"
import { Route } from "react-router-dom"
import Loadable from "components/Loadable"

const List = Loadable(lazy(() => import('../views/document/List')))
const TodoList = Loadable(lazy(() => import('../views/document/TodoList')))
const IncomingList = Loadable(lazy(() => import('../views/document/IncomingList')))
const OutgoingList = Loadable(lazy(() => import('../views/document/OutgoingList')))
const Form = Loadable(lazy(() => import('../views/document/Form')))
const Details = Loadable(lazy(() => import('../views/document/Details')))
const PrintQR = Loadable(lazy(() => import('../views/document/PrintQR')))
const PassDocument = Loadable(lazy(() => import('../views/document/PassDocument')))
const ReceiveDocument = Loadable(lazy(() => import('../views/document/ReceiveDocument')))
const CompleteDocument = Loadable(lazy(() => import('../views/document/CompleteDocument')))
const UnCompleteDocument = Loadable(lazy(() => import('../views/document/UnCompleteDocument')))


const routes = [
    <Route key={'document'} path='/' element={<List />} />,
    <Route key={'document.todo'} path='/document/todo' element={<TodoList />} />,
    <Route key={'document.incoming'} path='/document/incoming' element={<IncomingList />} />,
    <Route key={'document.outgoing'} path='/document/outgoing' element={<OutgoingList />} />,
    <Route key={'document.add'} path='/document/add' element={<Form />} />,
    <Route key={'document.edit'} path='/document/edit/:id' element={<Form />} />,
    <Route key={'document.details'} path='/document/details/:id' element={<Details />} />,
    <Route key={'document.print_qr'} path='/document/print-qr/:id' element={<PrintQR />} />,
    <Route key={'document.pass_document'} path='/document/pass/:ids' element={<PassDocument />} />,
    <Route key={'document.receive_document'} path='/document/receive/:ids' element={<ReceiveDocument />} />,
    <Route key={'document.complete_document'} path='/document/complete/:ids' element={<CompleteDocument />} />,
    <Route key={'document.un_complete_document'} path='/document/un-complete/:id' element={<UnCompleteDocument />} />,
]

export default routes