import { useDispatch, useSelector } from "react-redux"
import PropTypes from 'prop-types'
import { removeError } from '../../stores/error/action'
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material"
import { useLocation, useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import MCancelButton from "components/MCancelButton"

export const DialogError = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const navigation = useNavigate()
    const { state } = useLocation()

    const { openDialog, title, message } = useSelector((state) => state.error)
    const handleClose = () => {
        dispatch(removeError())
        let from = state?.previousPath || "/"
        navigation(from, { replace: true })
    }

    return (
        <div>
            <Dialog
                open={openDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                maxWidth={'xs'}
            >
                <DialogTitle id="alert-dialog-title" textAlign={'center'}>
                    {t(title)}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" textAlign={'center'}>
                        {message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ justifyContent: "center" }}>
                    <MCancelButton variant="outlined" onClick={handleClose}>
                        {t("Back")}
                    </MCancelButton>
                </DialogActions>
            </Dialog>
        </div>
    )
}

DialogError.propTypes = {
    openDialog: PropTypes.bool,
    title: PropTypes.string,
    message: PropTypes.string,
}