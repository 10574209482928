import { SET_USER } from "."
import AuthService from "../../requests/authRequest"
import Cookies from "js-cookie"
import { dateDiff } from "../../utils/common"
import { TOKEN, USER_PERMISSIONS } from "../../utils/constant"

export const login = (payload) => {
    return async () => {
        const response = await AuthService.login(payload)
        const expired = dateDiff(response.data.token_expires_at)

        Cookies.set(TOKEN, response.data.access_token, { expires: expired + 1 })
    }
}

export const resend = (payload) => {
    return async () => {
        const response = await AuthService.resend(payload)

        return response.data
    }
}

export const logout = async () => {
    await AuthService.logout()

    Cookies.remove(TOKEN)
    Cookies.remove(USER_PERMISSIONS)

    if (window.location !== '/login') {
        window.location = '/login'
    }
}

export const getCurrentUser = () => {
    return async (dispatch) => {
        const response = await AuthService.getCurrentUser()
        dispatch(SET_USER(response.data))

        Cookies.set(USER_PERMISSIONS, response.data.permissions)

        const expired = dateDiff(response.data.token_expires_at)
        const token = Cookies.get(TOKEN)
        Cookies.set(TOKEN, token, { expires: expired + 1 })

        return response.data
    }
}

export const updateUserProfile = (payload) => {
    return async (dispatch) => {
        const response = await AuthService.updateProfile(payload)
        dispatch(SET_USER(response.data))
        return response.data
    }
}

export const updatePassword = (payload) => {
    return async () => {
        const response = await AuthService.updatePassword(payload)

        return response.data
    }
}