export const LayoutSettings = {
    perfectScrollbar: false,
    leftSidebar: {
        show: true,
        mode: 'full', // full, close, mobile
        theme: 'dark',
    },
    topbar: {
        show: true,
        fixed: true,
        theme: 'white',
    }
}